export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const BASE_IMG_URL = process.env.REACT_APP_API_FILES_URL;
export const ENDPOINTS = {
  //auth
  UserLogin: `${BASE_URL}api/auth/login`,

  // User list
  UserPaginated: `${BASE_URL}api/admin/users-paginated`,
  UserDetails: `${BASE_URL}api/admin/user-detail/`,
  UserActiveDeActive: `${BASE_URL}api/admin/user-active-de-active`,

  // Faqs
  FaqsPaginated: `${BASE_URL}api/admin/faq-paginated`,
  FaqsAdd: `${BASE_URL}api/admin/add-faqs`,
  FaqUpdate: `${BASE_URL}api/admin/update-faqs`,
  FaqDelete: `${BASE_URL}api/admin/delete-faqs`,

  //chart
  UsersChart: `${BASE_URL}api/admin/chart/current-year-users`,
  ReportingChart: `${BASE_URL}api/admin/chart/current-year-reporting`,

  // reporting
  ReportingPaginated: `${BASE_URL}api/admin/reporting-paginated`,

  // symptoms
  SymptomsPaginated: `${BASE_URL}api/admin/symptoms-paginated`,

  AddSymptomsDisorders: `${BASE_URL}api/admin/add-symptoms-disorders`,
  UpdateSymptomsDisorders: `${BASE_URL}api/admin/update-symptoms-disorders`,

  // history-mood
  MoodHistory: `${BASE_URL}api/admin/mood-history`,

  // disorders
  DisordersPaginated: `${BASE_URL}api/admin/disorders-paginated`,

  // email
  EmailPaginated: `${BASE_URL}api/admin/email-paginated`,

  // DashboardData
  DashboardData: `${BASE_URL}api/admin/dashboard-data`,
  UpdateContent: `${BASE_URL}api/admin/update-content`,

  //barber
  UpdateBarber: `${BASE_URL}api/admin/update-barber`,
  CreateBarber: `${BASE_URL}api/admin/create-barber`,
  BarberDetails: `${BASE_URL}api/admin/barber-details`,
  BarberAppointments: `${BASE_URL}api/admin/barber-appointments`,
  BarberServices: `${BASE_URL}api/admin/barber-services`,
  BarberSkills: `${BASE_URL}api/admin/barber-skills`,
  BarberRating: `${BASE_URL}api/admin/barber-rating`,
  ClientRating: `${BASE_URL}api/admin/client-rating`,
  ClientsOrders: `${BASE_URL}api/admin/clients-orders`,

  // order
  OrderDetails: `${BASE_URL}api/admin/order-detail`,

  // list
  AllAdminsWithPagination: `${BASE_URL}api/admin/admins-profile`,
  AllBarbersWithPagination: `${BASE_URL}api/admin/barbers-profile`,
  AllUsersWithPagination: `${BASE_URL}api/admin/users-profile`,

  // app
  AppHeader: `${BASE_URL}api/admin/card-header`,
  UserChart: `${BASE_URL}api/admin/current-year-users`,
  OrdersChart: `${BASE_URL}api/admin/current-year-orders`,

  // adds
  AddsPaginated: `${BASE_URL}api/admin/adds-paginated`,
  AddsAdd: `${BASE_URL}api/admin/create-adds`,
  AddsUpdate: `${BASE_URL}api/admin/update-adds`,
  AddsDelete: `${BASE_URL}api/admin/adds-delete`,
  AddsActiveDeActive: `${BASE_URL}api/admin/adds-active-deactive`,

  //badges
  BadgesPaginated: `${BASE_URL}api/admin/badges-paginated`,
  BadgesCreate: `${BASE_URL}api/admin/create-badge`,
  BadgesUpdate: `${BASE_URL}api/admin/update-badge`,
  BadgesDelete: `${BASE_URL}api/admin/delete-badge`,
  SymptomsDisordersDelete: `${BASE_URL}api/admin/delete-symptoms-disorders`,

  // subcription
  SubcriptionPaginated: `${BASE_URL}api/admin/user-subcription-paginated`,
};
