import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import TableComponent from "../components/global/TableComponent";
import { isLoaderState } from "../recoil/RecoilStore";
import { apiPost } from "../util/ApiRequest";
import { ENDPOINTS } from "../util/EndPoint";

const Users = () => {
  const [reloadData, setReloadData] = useState(false);
  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);

  const navigate = useNavigate();

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: false,
      width: "60px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: false,
    },
    {
      name: "First name",
      selector: (row) => row.first_name,
      sortable: false,
    },
    {
      name: "Last name",
      selector: (row) => row.last_name,
      sortable: false,
    },
    {
      name: "Phone number",
      selector: (row) => row.phone_number,
      sortable: false,
    },

    {
      name: "Account status",
      selector: (row) => (
        <button
          onClick={() => UserActiveDeActiveHandle(row?.id)}
          className="btn btn-primary"
        >
          {row?.is_active == 1 ? "Deactive" : "Active"}
        </button>
      ),
      sortable: false,
    },
    {
      name: "Delete Account",
      selector: (row) => (
        <p className="mb-1">
          {row.is_deleted == 1 ? (
            <span className="bg-danger p-2 rounded-1">Yes</span>
          ) : (
            <span span className="bg-success rounded-1 p-2 ">
              No
            </span>
          )}
        </p>
      ),
      sortable: false,
    },
    {
      name: "Detail",
      selector: (row) => (
        <>
          <button
            onClick={() => navigate(`${"/admin/user-detail/"}${row.id}`)}
            className="btn btn-primary"
          >
            Detail
          </button>
        </>
      ),
      sortable: false,
    },
  ];

  const UserActiveDeActiveHandle = (userId) => {
    setIsLoaderInfo(true);
    apiPost(
      ENDPOINTS.UserActiveDeActive,
      { user_id: userId },
      (res) => {
        setReloadData(!reloadData);
        setIsLoaderInfo(false);
      },
      (error) => {
        console.log(error, " error");
        setIsLoaderInfo(false);
      }
    );
  };

  return (
    <>
      <TableComponent
        title="Users"
        endPoint={ENDPOINTS.UserPaginated}
        headers={columns}
        reloadData={reloadData}
        // searchObject={ }
      />
    </>
  );
};
export default Users;
